import React from 'react';

import CompetencesContainer, {
  COMPETENCES,
  genererFilAriane,
} from './container';

const GardeEnfant = () => {
  return (
    <CompetencesContainer currentSection={COMPETENCES.GARDE_ENFANT}>
      <h1>Garde d'enfant</h1>

      {genererFilAriane("garde d'enfant")}

      <hr />

      {/* Description */}

      <div id="texteImg">
        <img
          className="garde"
          src="/img/competences_sprite.jpg"
          alt="Garde d'enfant"
        />
      </div>

      <p>
        Résoudre les divergences relatives aux droits de{' '}
        <span className="accent">garde des enfants</span> et aux{' '}
        <span className="accent">droits de visites</span> des parents a un
        impact important sur la famille et son fonctionnement dans le futur. Nos
        avocats font preuve de <span className="accent2">patience</span>,{' '}
        <span className="accent2">diplomatie</span> et{' '}
        <span className="accent2">détermination</span> dans la résolution de ces
        problèmes en ayant toujours comme objectif premier le meilleur intérêt
        des enfants.
      </p>
    </CompetencesContainer>
  );
};

GardeEnfant.propTypes = {};

export default GardeEnfant;
